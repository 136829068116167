import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { ProductModel } from '../models/product.model';
import { QrMenu } from '../models/qr-menu.model';
import { ProductTypeModel } from '../models/productType.model';

@Injectable({
  providedIn: 'root',
})
export class ProductMenuService {
  baseUrl = environment.apiUrl;
  constructor(private http: HttpClient) {}

  //#region R&D
  getSellableProducts(customUrl: string): Observable<QrMenu> {
    return this.http.get<QrMenu>(`${this.baseUrl}/Menu/${customUrl}/`).pipe(
      map((response: any) => {
        let products: Array<ProductModel> = [];
        let productList = response.menuItems;

        for (let i: number = 0; i < productList.length; i++) {
          let prod: ProductModel = new ProductModel();

          prod = productList[i];
          prod.productMasterId = productList[i].productMasterId;
          prod.productDisplayName = productList[i].productDisplayName;
          prod.sellPrice = productList[i].sellPrice;
          prod.costPrice = productList[i].costPrice;
          if (
            productList[i].defaultImgStr == null ||
            productList[i].defaultImgStr.length <= 0
          ) {
            prod.defaultImgStr = '../../assets/productMenu/addon.jpg';
          } else {
            prod.defaultImgStr = `${environment.imgUrl}${productList[i].defaultImgStr}`;
          }

          let prodCategory = new ProductTypeModel();
          prod.productTypeId = productList[i].productTypeId;
          prodCategory.productTypeId = productList[i].productTypeId;
          prodCategory.categoryName =
            productList[i].productType.categoryDisplayName;
          prod.productType = prodCategory;

          products.push(prod);
        }

        if (response.imgUrl != null && response.imgUrl.length > 0) {
          response.imgUrl = `${environment.imgUrl}${response.imgUrl}`;
        } else {
          response.imgUrl = '../../assets/productMenu/addon.jpg';
        }

        response.menuItems = products;
        return response as QrMenu;
      })
    );
  }

  getSellableProducts2(
    restaurantName: string,
    branchName: string,
    table: number
  ) {
    return this.http.get(
      <any>`${this.baseUrl}/${restaurantName}/${branchName}/${table}`
    );
  }

  getProducts(branchId: number): Observable<any> {
    return this.http.get<any>(
      `${this.baseUrl}/api/ProductMenu/GetProducts/${branchId}`
    );
  }
  //#endregion

  //#region Main
  QRPrint(
    items: any[],
    restaurantName?: string,
    branchName?: string,
    customUrl?: string,
    tableId?: number
  ): Observable<any> {
    if (items.length > 0) {
      return this.http.post<any>(
        `${this.baseUrl}/api/Product/QRDownload`,
        items,
        { responseType: 'blob' as 'json' }
      );
    } else {
      const body = [
        {
          restaurantName: restaurantName,
          branchName: branchName,
          customUrl: customUrl,
          tableId: tableId,
        },
      ];
      return this.http.post<any>(
        `${this.baseUrl}/api/Product/QRDownload`,
        body,
        { responseType: 'blob' as 'json' }
      );
    }
  }
  //#endregion

  getCustomerOrderList(orderList: any) {
    let token = localStorage.getItem('qr_auth_app_token');

    if (token) {
      token = token.replace(/"/g, '');
    }

    // Start with required parameters
    let params = new HttpParams()
      .set('CustomerId', orderList.customerId)
      .set('PageNumber', orderList.PageNumber)
      .set('PageLength', orderList.PageLength);

    // Conditionally add optional parameters
    if (orderList.RestuarantName) {
      params = params.set('RestuarantName', orderList.RestuarantName);
    }

    if (orderList.OrderDate) {
      params = params.set('OrderDate', orderList.OrderDate);
    }
    console.log("params",params);

    const headers = new HttpHeaders({
      Authorization: `Bearer ${token}`,
    });

    return this.http.get<any>(
      `${this.baseUrl}/api/ProductMenu/CustomerOrderList`,
      { params, headers }
    );
  }



  //#region GET Customer Details
  GetQRCustomerDetail(customerId: number, token: string) {
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${token}`
    });
    return this.http.get<any>(`${this.baseUrl}/api/Profile/GetQRCustomerDetail?customerId=${customerId}`, {headers});
  }

  updateCustomerProfileInfo(data: any) {
    var token = localStorage.getItem("qr_auth_app_token");

    if (token) {
      token = token.replace(/"/g, '');  // Removes any quotes from the token
    }

    const headers = new HttpHeaders({
      'Authorization': `Bearer ${token}`
    });
    console.log("TOKEN FROM SRVICE", headers, token);
    return this.http.post<any>(`${this.baseUrl}/api/Profile/ProfileUpdate/`, data, {headers});
  }
  //#endregion

  //#region  app qr download
  AppQRDownload(qr:string): Observable<any> {
    return this.http.get<any>(
      `${this.baseUrl}/api/Outlet/RedirectToOriginalUrl?code=${qr}`,
      { responseType: 'blob' as 'json' }
    );
  }
  //#endregion
}
